.dynamicsitemap-index-index {
    .siteul li {
        list-style: none;
        
        &:before {
            margin: 8px 10px 0 0;
        }
        
        a:hover {
            text-decoration: none;
            color: $alizarin;
            font-weight: 700;
        }
    }
    
    .cat {
        a:hover {
            text-decoration: none;
            color: $alizarin;
            font-weight: 700;
        }
        
        &:before {
            margin: 11px 10px 0 0;
        }
    }
}