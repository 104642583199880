.header-top-links {
    float: right;
    padding: 20px 0 10px 60px;
    @include remtopx(14);
    text-transform: uppercase;
    min-width: 545px;
    font-family: arial;
    text-align: right;
    
  	a {
        color: $black;
        padding: 0 15px;
        @include remtopx(10);

        &:hover {
            color: $alizarin;
        }
    }

    a.last {
        padding-right: 0;
    }
}

