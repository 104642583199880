/*------------------------------------*\
            Website Mixins
\*------------------------------------*/

// REM to PX Converter
@mixin remtopx($sizeValue) {
   $remValue: ($sizeValue / 10);
   $pxValue: $sizeValue;
   font-size: $pxValue+px; 
   font-size: $remValue+rem;
}
