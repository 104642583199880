.featured-content-block {
	width: 1004px;
	margin: 0 auto;
	clear: both;
	height: 105px;
	margin-bottom: 40px;
}

.featured-content-block__item {
	float: left;
	@include linear-gradient(to bottom, $alizarin, $faluRed);
	width: 328px;
	height: 110px;
	margin-right: 10px;
}

.featured-content-block__item {
	h3, span {
		font-family: $third-font;
		color: $white;
	}
}

.featured-content-block__item h3 {
	text-transform: uppercase;
	text-align: center;
	margin: 0;
	padding: 17px 0 0 0;
	@include remtopx(23);
}

.featured-content-block li:before {
	content: none;
}

.featured-content-block__item span {
	display: block;
	text-align: center;
	margin: 0;
	padding: 0;
	@include remtopx(17);
}

.featured-content-block__item--shopping img,
.featured-content-block__item--delivery img, 
.featured-content-block__item--phone img {
	margin: 0 auto;
	padding: 13px 0 0 0;
}

.featured-content-block__item--phone {
	margin-right: 0;
}

.featured-content-block__item--delivery h3 {
	padding-top: 10px;
}

.featured-content-block__item--phone h3 {
	padding-top: 10px;
}




