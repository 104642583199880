.product-carousel-container {
    margin-top: 60px;
}

#product-tabs {
    width: 100%;
    height: 42px;
    margin: 30px 0 0;

    li {
        position: relative;
        float: left;
        list-style: none;
        border-top: 1px solid $silver;
        border-left: 1px solid $silver;
        border-right: 1px solid $silver;
        margin-right: 10px;
        outline: none;
        @include transition($transitionDefault);
        
        &:before {
            content: none;
        }

        a {
            padding: 12px 50px 9px;
            display:block;
            background: $white;
            text-decoration:none;
            outline:none;
            cursor: pointer;
            font-family: $secondary-font;
            @include remtopx(14);
            @include transition($transitionDefault);
            
            &:hover {
                color: $black;
            }

            &.inactive {
                padding: 10px 50px;
                color: $grey;
                background: $gainsboro;
                outline: none;
                @include remtopx(14);
            }
        }
    }
}

.product-carousel-wrapper {
    padding: 30px 25px 0;
    border: solid 1px $silver;
}

.product-tab-inner {
    .slick-prev, .slick-next {
        background: $veryLightGrey;
    }
}

.product-tab-content {
    clear: both;
    width: 100%;
    overflow: hidden;
    height: 0;
    
    .slick-list {
        max-width: 1035px;
        width: 100%;
        margin: 0 auto;
    }
}

.visible {
    height: auto;
}

.product-tab-item {
    position: relative;
    float: left;
    text-align: center;
    margin-right: 25px;
    
    .rating-box {
        margin: 0 auto;
    }
}

.product-tab-item-image {
    max-width: 213px;
    width: 100%;
    margin: 0 auto;
    border: 1px solid $silver;
}

.product-tab-item-name {
    margin: 10px 0;
    @include remtopx(14);
    font-family: $secondary-font;
    text-transform: capitalize;
}

.product-tab-item-price {
    font-family: $secondary-font;
    @include remtopx(12);
    color: $alizarin;
}

.rating {
    unicode-bidi: bidi-override;
    direction: rtl;
    clear: both;
}

.rating > span {
    display: inline-block;
    position: relative;
    width: 1.2em;
    @include remtopx(18);
    font-family: 'flaticon';
    color: $silver;
    
    &:before {
        content: "\e00a";
        position: absolute;
        color: $silver;
    }
}