.product-detail-carousel-container {
    float: left;
    max-width: 510px;
    width: 100%;
    padding-right: 20px;
    box-sizing: content-box;
    overflow: hidden;
}

.product-image-block {
    max-width: 350px;
    float: right;
    position: relative;
    
    img {
        width: 100%;
    }
}

.product-image-nothumb {
    max-width: none;
    float: left;
    width: 100%;
}

.product-thumbs {
    float: left;
    max-width: 121px;
    height: 376px;
    
    img {
        width: 100%;
        display: block;
    }
    
    .slick-prev, .slick-next {
        width: 100%;
        height: 45px;
        border-radius: 0;
        background-color: rgb(150, 165, 166);
        background-color: rgba(150, 165, 166, 0.9);
        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#96a5a6, endColorstr=#96a5a6);
        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#96a5a6, endColorstr=#96a5a6)";
        
        &:hover {
            background-color: rgb(187, 19, 21);
            background-color: rgba(187, 19, 21, 0.9);
            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#bb1315, endColorstr=#bb1315);
            -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#bb1315, endColorstr=#bb1315)";
        }
    }
    
    .slick-prev {
        display: block;
        top: 50px;
        
        &:before {
            color: $black;
            display: inline-block;
            -webkit-transform: rotate(270deg);
            -moz-transform: rotate(270deg);
            -o-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: rotate(270deg);
        }
    }
    
    .slick-next {
        display: block;
        top: 376px;
        
        &:before {
            color: $black;
            display: inline-block;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }
    
    .slick-list {
        height: 100%;
        max-height: 376px;
        
        .slick-slide {
            max-width: 125px;
            max-height: 125px;
            padding: 5px 0;
            box-sizing: content-box;
        }
    }
}