/*------------------------------------*\
            Footer Styles
\*------------------------------------*/
.footer {
	height: 220px;
	width: 1130px;
    margin: 0 auto;
	background-color: $grey;
	border-top: none;
}

.footer-container {
    margin: 0 auto;
    width: 1130px;
    padding: 10px 0 0;
    background: white;
}

.link-panel {
  float: left;
  width: 180px;
  margin-left: 28px;
  h3 {
  	text-transform: none;
  	color: $white;
  	font-family: $third-font;
  }
}












