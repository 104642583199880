/*------------------------------------*\
            Social Link Styles
\*------------------------------------*/
.connect-with-us__item {
	float: left;
	display: block;
	color: $white;
 }

.connect-with-us__item--facebook span  {
	padding-right: 10px;

	&:hover {
		color: $facebook; 
	}
}

.connect-with-us__item--twitter span  {
	&:hover {
		color: $twitter;
	}
}

.connect-with-us li:before {
	content: none;
}

.connect-with-us h3 {
	color: $white;
	text-transform: none;
}