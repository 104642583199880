/*------------------------------------*\
        Footer Links Styles
\*------------------------------------*/
.footer-links {
	margin: 0 auto;
	width: 1060px;
	clear: both;
	a, p {
		color: $white;
		font-family: $third-font;
	}
}

.footer-links li {
	@include remtopx(13);
}

.bottom-links {
	width: 980px;
  	margin: 0 auto;
  	position: relative;
  	float: left;
}

.quick-links {
	padding-top: 50px;
}

.quick-links li {
	float: left;
	padding: 0 20px;
	@include remtopx(13);
}

.quick-links li:before {
	content: none;
}

.rippleffect {
	float: right;
}