#header-cart {
    position: relative;
    margin-left: 15px;
    float: left;
    height: 37px;
    width: 285px;
    border: 1px solid $veryLightGrey;
    
    &:before {
        float: left;
        display: block;
        text-align: center;
        width: 40px;
        height: 36px;
        color: $white;
        background: $veryLightGrey;
        font-family: 'Flaticon';
        content: '\e000';
        @include remtopx(18);
        text-indent: 0;
        line-height: 1.9em;
    }
}

/* Override */

.cart-menu .empty {
    padding: 7px;
    @include remtopx(12);
    color: $black;
}