#header-search {
    float: left;
    display: block;
    position: relative;
    top: inherit;
    overflow: hidden;
    width: 300px;
    height: 43px;
    padding: 0;
    
    .search-button {
        background: $veryLightGrey;
        padding: 0;
        height: 37px;
        
        &:before {
            color: $white;
            background: none;
            font-family: 'Flaticon';
            content: '\e001';
            @include remtopx(18);
            text-indent: 0;
            line-height: 1.6em;
            font-weight: 700;
        }
    }
}

#search {
    width: 240px;
    height: 35px;
    padding-left: 20px;
    line-height: 2em;
}

