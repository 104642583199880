.checkout-onepage-index {
    .col-main {
        width: 100%;
    }
}

.opc-firststep-login {
    li {
        &:before {
            content: none;
        }
    }
}