.header-quick-access {
    float: right;
    width: 600px;
    margin-top: 10px;
}

.company-number {
    float: right;
    padding: 17px 0 10px;
    font-family: $third-font;
    @include remtopx(12);
}