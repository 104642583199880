.catalog-category-view {
    .main-container {
        padding-top: 65px;
    }
}

.category-products {
    .item:before {
        content: none;
    }
}

.add-to-links {
    display: none;
}

.products-grid .product-info {
    padding: 0;
}

.product-info {
    min-height: inherit!important;
    
    .product-name {
        text-align: left;
        margin: 10px 0;
        @include remtopx(14);
        font-family: $secondary-font;
        text-transform: capitalize;
    }
    .actions {
        display: none;
    }
}

.no-touch .product-image:hover {
    border-color: $cinnabar;
}

.price-box {
    text-align: left;
    
    .price {
        font-family: $secondary-font;
        @include remtopx(12);
        color: $alizarin;
    }
}

.btn-cart, .actions .button {
    width: 100%;

      &:hover, &:focus {
        background-color:$lightGrey;
        outline:none;
    }
}