/*------------------------------------*\
            Newsletter Styles
\*------------------------------------*/
.newsletter {
    height: 110px;
    width: 1130px;
    background-color: $black;
    margin: 0 auto;
    padding: 0;
}

.newsletter-inner {
	width: 1004px;
	margin: 0 auto;
}

.newsletter-text {
	margin: 0 auto;
	float: left;
	clear: both;
}

.newsletter-text {
	h3, p {
		font-family: $third-font;
		color: $white;
	}
}

.newsletter-text h3 {
  padding: 0 20px 0 10px;
  position: relative;
  display: inline-block;
  padding-top: 45px;
  clear: both;
}

.newsletter-text p {
  display: inline-block;
  vertical-align: text-top;
  position: relative;
  @include remtopx(13);
  font-weight: bold;
}

.newsletter-text span {
	float: left;
	display: block;
 	text-indent: -9999px;
 	list-style: none;
 	height: 54px;
 	width: 44px;
 	background: url('../../images/logo-white.png') no-repeat;
 	margin-top: 26px;
}

.newsletter-submit {
  	float: right;
  	margin-top: 43px;
}

.newsletter-submit input {
  -webkit-appearance: none;
  border: 0;
  background: #fff;
  padding: 11px 0 10px 10px;
  width: 283px;
  font-size: 13px;
  display: inline-block;
}

.newsletter-submit span {
	color: $white;
	background: $grey;
	font-family: $third-font;
	text-transform: uppercase;
	padding: 10px 30px 10px 20px;
}

.newsletter-btn {
	background: $grey;
	font-family: $third-font;
	padding: 5px 20px 4px 20px;
	@include remtopx(14);

	&:hover, &:focus {
    	background-color:$alizarin;
    	outline:none;
	}
}


