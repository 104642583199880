@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/openSans/regular/opensans-regular-webfont.eot');
    src: url('../fonts/openSans/regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/openSans/regular/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/openSans/regular/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/openSans/regular/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/openSans/regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futura_bkbook';
    src: url('../fonts/futura/futura-book-webfont.eot');
    src: url('../fonts/futura/futura-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/futura/futura-book-webfont.woff2') format('woff2'),
         url('../fonts/futura/futura-book-webfont.woff') format('woff'),
         url('../fonts/futura/futura-book-webfont.ttf') format('truetype'),
         url('../fonts/futura/futura-book-webfont.svg#futura_bkbook') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Flaticon';
	src: url('../fonts/iconFonts/flaticon.eot');
	src: url('../fonts/iconFonts/flaticon.eot#iefix') format('embedded-opentype'),
	url('../fonts/iconFonts/flaticon.woff') format('woff'),
	url('../fonts/iconFonts/flaticon.ttf') format('truetype'),
	url('../fonts/iconFonts/flaticon.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
    font-style: normal;
}

.flaticon-cart4:before {
	content: "\e000";
}

.flaticon-dark37:before {
	content: "\e001";
}

.flaticon-facebook55:before {
	content: "\e002";
}

.flaticon-keyboard53:before {
	content: "\e003";
}

.flaticon-twitter1:before {
	content: "\e004";
}