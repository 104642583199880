.catalog-product-view {
    .product-name span {
        font-family: $secondary-font;
        @include remtopx(22);
        text-transform: capitalize;
        font-weight: 300;
        color: $nightRider!important;
    }
    
    .product-shop {
        padding: 0 0 10px;
        border-bottom: 1px solid $silver;
    }
    
    .product-view .product-shop .price-info {
        width: 100%;
        max-width: none;
        float: left;
        padding-left: 0px;
        text-align: left;
    }
    
    .product-view .product-shop .price-box .regular-price .price {
        @include remtopx(18);
        color: $alizarin;
        line-height: 2em;
    }
    
    .short-description .std, .tab-content .std{
        font-family: $primary-font;
        color: $black;
        @include remtopx(12);
        line-height: 2em;
    }
    
    .qty-wrapper label {
        min-width: 25px;
    }
    
    .product-view .add-to-cart-buttons {
        float: left;
        display: inline-block;
        clear: both;
        
        .button {
            cursor: pointer;
            @include linear-gradient(to bottom, $alizarin, $faluRed);
            color: $white;
            min-width: 220px;
            height: 45px;
            @include remtopx(18);
            text-transform: uppercase;
            font-weight: 700;
            font-family: $secondary-font;
        }
    }
    
    .collateral-tabs {
        border-top: 1px solid #cccccc;
    }
    
    .product-collateral {
        width: 500px;
        max-width: 500px;
        float: right;
        
        .toggle-tabs {
            border: none;
            background: none;
            
            li {
                margin-right: 10px;
                color: $black;
                border-left: 1px solid #cccccc;
                border-right: 1px solid #cccccc;
                border-top: 1px solid #cccccc;
                background: $gainsboro;
                
                span {
                    color: $black;
                    text-transform: capitalize;
                    font-family: $secondary-font;
                    @include remtopx(14);
                }
            }
            
            .current {
                border-left: 1px solid #cccccc;
                border-top: 1px solid #cccccc;
                background: none;
            }
            
            li:before {
                content: none;
            }
        }
    }
    
    .link-wishlist {
        background: $black;
        color: $white;
        min-width: 220px;
        height: 35px;
        @include remtopx(14);
        text-transform: capitalize;
        font-weight: 700;
        font-family: $secondary-font;
    }
    
    .sharing-links, .link-compare {
        display: none;
    }
    
    .product-view .add-to-cart {
        border: none;
        margin-bottom: 0;
    }
}

.product-options {
    margin-top: 30px;
}

.product-options, .product-options-bottom {
    padding: 0;
}

.product-options, .product-options-bottom {
    border: none;
    background: none;
}

.product-options {
    .required-entry {
        width: 100px;
    }
    
    label {
        line-height: 2.5em;
        min-width: 50px;
    }
}

.product-options p.required {
    top: -5px;
    right: 140px;
}