.cms-page-view .std {
    h1, h2,h3, h4,h5, h6 {
        color: $nightRider;
    }
    
    p, p span {
        font-family: $primary-font;
        line-height: 1.5em;
        font-style: normal;
        @include remtopx(14);
    }
    
    ol, ul {
        li {
            list-style: none;
            font-family: $secondary-font;
            font-style: normal;
            
            &:before {
                margin: 9px 10px 0 0;
            }
        }
    }
    
    div {
        margin: 15px 0;
    }
    
    td {
        text-align: center;
        background: $gainsboro;
    }
}