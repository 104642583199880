.toolbar {
    border: none;
    background: none;
    padding: 0;
    
    select {
        min-width: 70px;
    }
}

.pager {
    float: left;
}

/* Sorter Styling */

.sorter {
    float: right;
    max-width: 220px;
    width: 100%;
}

.sorter > .sort-by {
    float: right;
}

/*View Mode Styling */

.sorter > .view-mode {
    float: left;
}

/* Toolbar Label Styling */

.view-mode label, .sort-by label, .limiter label {
    min-width: 50px;
}

/* Counter Styling */

.count-container {
    .amount {
        margin: 0 15px 0 0;
        line-height: 2.5em;
    }
}