.benefits {
    li {
        list-style: none;
    }
}

.form-list {
    label {
        @include remtopx(14);
    }
    .control input.checkbox {
        margin-top: 17px;
    }
}

//Account Override
.customer-account-forgotpassword .fieldset .input-box input.input-text {
    border: $black 1px solid;
}

#my-orders-table tbody td, #my-orders-table tfoot td {
    font-family: $primary-font;
}

#my-orders-table thead th, .cart-table thead th {
    background: $grey;
}

#my-orders-table tbody td {
    background: $gainsboro;
}