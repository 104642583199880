/*------------------------------------*\
            Base Styles
\*------------------------------------*/
html {
   @include box-sizing(border-box);
   font-size: 62.5%;
}
*, *:before, *:after { @include box-sizing(inherit); outline: none; }

body {
   @include remtopx(14); // Base pixel size for site
   background-color: $white;
   color: $black;
   font-family: $primary-font;
   -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  @include box-sizing(inherit);
  outline: none;
}

h1, h2, h3, h4, h5, h6 { 
  font-family: $secondary-font;
  text-transform: uppercase;
  color: $nightRider;
  line-height: 1em;
}

h1 {
  @include remtopx(30);
}
h2 {
  @include remtopx(22);
}
h3 {
  @include remtopx(20);
}
h4 {
  @include remtopx(18);
}
h5 {
  @include remtopx(16);
}
h6 {
  @include remtopx(14);
}

p {
  line-height: 1.5em;
  color: $nero;
  @include remtopx(12);
}

.alt-font {
  font-family: $secondary-font;
}

a {
  color: $black;
  @include transition($transitionDefault);
  &:hover, &:focus {
    color: $alizarin;
  }
}


button {
  font-family: $secondary-font;
  color: white;
  padding:10px 45px;
  @include linear-gradient(to bottom, $alizarin, $faluRed);
  border: 0;
  text-transform: uppercase;
  @include remtopx(17.5);

  &:hover, &:focus {
    background-color:$red;
    outline:none;
  }
}

.button {
    background: $gainsboro;
    color: #636363;
    padding: 7px 15px;
    
    &:hover, &:focus {
        background: $lightGrey;
    }
}

.secondary-btn {
  background-color:$black;
  background-image: none;
  text-transform: none;
  @include remtopx(14.5);
  padding:8px 45px;

  &:hover {
    background-color: lighten($black, 20%);
  }
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

li {
  &:before {
    content: "";
    line-height: 1em;
    width: .5em;
    height: .5em;
    background-color: $shadyLady;
    float: left;
    margin: 5px 10px 0 0;
  }
}

.data-table {
    th {
        background: $grey;
        border-right: 1px solid $silver;
        color: $white;
    }
    
    tbody td {
        background: $gainsboro;
    }
}

table {
  margin:20px 0;
  width:100%;
}

table tfoot + tbody > tr:first-child > td {
  background-color:inherit;
  color:inherit;
}

td {
  border-bottom:1px solid $lightgrey;
  padding:10px;
}

label {
  float:left;
  padding-right:10px;
  font-family:$secondary-font;
  font-weight:500;
  line-height: 32px;
  min-width: 140px;
  text-transform: uppercase;
  font-size: rem(14);
}

input, textarea {
  background-color: white;
  border:1px solid $silver;
  margin-bottom:20px;
  padding:6px 10px;
  @include remtopx(14);
  text-overflow: ellipsis;
  font-family: $third-font;
}

textarea {
  max-width: 800px;
  width:100%;
}

select {
  margin-bottom:20px;
  font-family: $third-font;
  min-width: 200px;
  padding: 10px;
  webkit-appearance: none;
}

.sg-colour {
   display:inline-block;
   height:40px;
   width:40px;
   margin:20px;
   border:1px solid #CCC;
}
.sg-colour-1 {
   background: $gainsboro;
}
.sg-colour-2 {
   background: $white;
}
.sg-colour-3 {
   background: $black;
}
.sg-colour-4 {
   background: $veryLightGrey;
}
.sg-colour-5 {
   background: $grey;
}

.sg-colour-6 {
   background: $silver;
}
.sg-colour-7 {
   background: $cinnabar;
}
.sg-colour-8 {
   background: $alizarin;
}
.sg-colour-9 {
   background: $faluRed;
}

::-webkit-input-placeholder {
    @include remtopx(12);
    color: $black;
}
:-moz-placeholder {
    @include remtopx(12);
    color: $black;
}
::-moz-placeholder {
    @include remtopx(12);  
    color: $black;
}
:-ms-input-placeholder {
    @include remtopx(12);  
    color: $black;
} 
