/*------------------------------------*\
        Payment Options Styles
\*------------------------------------*/
.payment__item {
	float: left;
	display: block;
 	text-indent: -9999px;
 	list-style: none;
 	height: 30px;
 	width: 50px;
}

.payment__item--visa  {
	background: url('../../images/visa-card.png') no-repeat;
 	margin-right: 10px;
}

.payment__item--mastercard {
	background: url('../../images/mastercard.png') no-repeat;
 	margin-right: 10px;
}

.payment__item--amex {
	background: url('../../images/amex-card.png') no-repeat;
}

.payment__item--paypal {
	background: url('../../images/paypal-card.png') no-repeat;
 	margin-top: 10px;
}

.payment li:before {
	content: none;
}