/*------------------------------------*\
            Global Styles
\*------------------------------------*/

.col1-layout, .col2-left-layout,
.col2-right-layout, .col3-layout {
    width: 1130px;
    padding: 20px 64px;
    background: white;
}

.wrapper {
    background: url('../../images/bg.jpg') top no-repeat;
    background-size: cover;
}

.cms-index-index .page-title {
    display: none;
}

// Site Wide Overrides

.disc li {
    list-style: none;
}

.cms-no-route .std h1 {
    color: $alizarin;
}

.block-account li strong, .block-account li a:hover {
    color: $alizarin;
    font-weight: 700;
    text-decoration: none;
}

.hideprice {
    text-align: left;
}

// Notification Messages

.messages .error-msg li:before,
.messages .success-msg li:before,
.messages .notice-msg li:before,
.messages .warning-msg li:before {
    background: transparent;
}
