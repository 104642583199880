/*------------------------------------*\
            Cart Styles
\*------------------------------------*/

.cart-menu {
    padding: 11px 11px 12px;
    overflow: hidden;
    display: inline-block;
    width: 243px;
}

.header-cart {
    line-height: 2.5em;
    height: auto;
    text-transform: capitalize;
    font-family: $third-font;
    @include remtopx(12);
    color: $black;
    position: relative;
    
    &__label {
        font-weight: bold;
        padding-right: 5px;
    }
    
    &__label, &__count {
        float: left;
        line-height: 1em;
    }
    
    &__total {
        float: right;
        line-height: 1em;
    }
}

.opc li:before {
    content: none;
}