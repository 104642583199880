/*------------------------------------*\
          Navigation Styles
\*------------------------------------*/

.nav-primary {
    /* Override */
    
    a, a.level0 {
        color: $white;
        font-family: $secondary-font;
        font-weight: 700;
        padding: 1px 0;
    }

    li:hover > a {
        color: $white;
    }
}

.level0 {
    background: $black;
    width: 112px;
    text-align: center;
    border: none;
    margin-right: 1px;
    
    &:hover {
        @include transition($transitionDefault);
        @include linear-gradient(to bottom, $alizarin, $faluRed);
    }
    
    &:before {
        content: none;
    }

    a {
      border: none;
      width: 100%;
    }
}

/* Main Nav social links */
.level0--facebook, .level0--twitter {
    width: 44px;
    
    a {
        display: block;
        text-indent: -9999px;
        position: relative;
        text-align: center;
        padding: 1px 0;
        
        .flaticon-facebook55, .flaticon-twitter1 {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-indent: 0;
            color: $white;
            @include remtopx(14);   
        }
    }
}