/*------------------------------------*\
            Site Variables
\*------------------------------------*/
/* Bourbon Variables */
$em-base : 16px;

/* Default Variable Colours */
$red : #EB3F45;
$darkred : #C8222E;
$maroon : #8A0B1A;
$black : #2D2D2D;
$lightgrey : #DEDEDE;
$darkgrey : #7F7F7F;
$text : $black;

/* Xamax base colours */
$black : #000000;
$gainsboro : #dedede;
$lightGrey: #d0d0d0;
$veryLightGrey : #cccccc;
$grey : #7f7f7f;
$white : #ffffff;
$silver : #bcbcbc;
$cinnabar : #eb3f45;
$alizarin : #e33036;
$faluRed : #7F0B15;
$nightRider : #313131;
$nero : #2c2c2c;
$shadyLady : #969696;

/* transition variable */
$transitionDefault : all 0.5s ease-in-out;

/* Social Media */
$facebook : #49639E;
$twitter : #00ACEE;
$linkedin : #0976B4;
$youtube : #E52D27;
$google : #DD4B39 ;

$primary-font : 'open_sansregular', sans-serif;
$secondary-font : 'futura_bkbook', serif;
$third-font : 'myriad_pro', arial, Helvetica;