.sidebar {
    .block-title strong span {
        font-family: $secondary-font;
        color: $nightRider;
        @include remtopx(18);
        text-transform: capitalize;
        font-weight: 300;
    }
}

#narrow-by-list {
    
    dt {
        font-family: $secondary-font;
        color: $nightRider;
        @include remtopx(18);
        text-transform: capitalize;
        font-weight: 300;
        border: none;
        background: none;
        padding: 10px 0 0;
    }
    
    dd {
        border-bottom: 1px solid $silver;
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 20px 0;
        
        li {
            padding: 5px 0;
        
            &:before {
                content: none;
            }
        
            a {
                padding: 0;
            }
        }
    }
}

.category-desc-container {
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
}

.category-description {
    font-family: $primary-font;
    @include remtopx(12);
    line-height: 2em;
}