.checkout-cart-index {
    .shipping .shipping-country {
        width: 100%;
    }
}

.form-list li:before,.checkout-types li:before,
.cart-links li:before {
    content: none;
}

.checkout-cart-index {
    .product-name {
        @include remtopx(14);
        font-family: $secondary-font;
    }
}

.cart {
    form {
        max-width: 602px;
        overflow: hidden;
        float: left;
    }
}

.cart-table {
    width: 602px;
}

.cart-forms {
    float: left;
    clear: left;
    padding: 0;
}

.cart .cart-totals table tfoot strong {
    @include remtopx(18);
    font-family: $secondary-font;
}

.cart-totals button:hover, .cart-totals button:active,
.cart-totals button:focus {
    background: $lightGrey;
}

//One Page Checkout Overrides

.opc .step-title h2 {
    color: $grey!important;
}

.opc .section.allow .step-title .number {
    background-color: $cinnabar;
}

.opc .section .step-title .number,
.no-touch .opc .section.allow:hover .step-title .number,
.opc .section.allow.active .step-title .number {
    background-color: $alizarin;
}

.form-list .control input.radio,
.sp-methods dt input.radio {
    margin-top: 15px;
}

.sp-methods label {
    @include remtopx(16);
}

#co-shipping-method-form .buttons-set .button {
    background: $gainsboro;
    color: #636363;
    padding: 7px 15px;
    
    &:hover, &:focus {
        background: $lightGrey;
    }
}

#checkout-review-table tr th {
    color: $nightRider;
}

.address-select {
    float: left;
    clear: both;
}