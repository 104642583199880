.breadcrumbs {
    width: 100%;
    overflow: hidden;
    display: block;
    padding: 10px 0 15px;
    border-bottom: solid 1px $silver;
    
    .breadcrumb-break {
        padding: 0 20px 0 10px;
        color: $grey;
    }
}

.breadcrumb-link {
    float: left;
    
    &:before {
        content: none;
    }
    
    strong {
        text-transform: capitalize;
        color: $black;
        font-family: $secondary-font;
    }
    
    a {
        @include transition($transitionDefault);
        @include remtopx(12);
        font-family: $secondary-font;
        color: $grey;
        text-transform: capitalize;
            
        &:hover {
            color: $alizarin;
        }
    }
}

.breadcrumb-active {
    @include remtopx(12);
    color: $black;
}