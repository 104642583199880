
.slick-prev:before, .slick-next:before {
    font-family: 'Flaticon';
}

.slick-prev, .slick-next {
    background: $black;
    height: 90px;
    width: 25px;
    opacity: 0.6;
    margin-top: -45px;
    &:hover, &:focus {
        background: $black;
    }
}

.slick-prev {
    left: 0;
   
    &:before {
        display: inline-block;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        content: "\e003"; 
    }
}

.slick-next {
    right: 0;

    &:before {
        content: "\e003"; 
    }
}

.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    li {
        height: 12px;
        width: 12px;
        cursor: pointer;
        background: $black;
        border: 1px solid $grey;
        bottom: 18px;

        &:before {
            content: none;
        }
        button {
            &:before {
                content: none;
            }
        }

        &.slick-active {
            background: $red;
        }
    }
}